<template>
    <v-row align="center" justify="center" class="my-5">
        <v-col cols="6" align="center">
            <v-alert
                :value="showAlert"
                outlined
                transition="scale-transition"
                :type="type"
            >
                {{ message }}
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            showAlert:
                {
                    type: Boolean,
                    default: false
                },
            message:
                {
                    type: String,
                    default: ''
                },
            type:
                {
                    type: String,
                    default: 'error'
                }
        },
        data() {
            return {
            }
        },
        watch: {
        },
        created() {
        },
        methods: {
        }
    }
</script>

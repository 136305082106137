import paronApi from '@/utils/paron-api'

export default {
    getAllCards(page) {
        var path = page ? `card/all?page=${page}&per_page=10` : 'card/all'
        return paronApi.get(path)
    },

    getCardById(id) {
        return paronApi.get(`card/${id}`)
    },

    createCard(data) {
        return paronApi.post('card/new', data)
    },

    editCardById(id, data) {
        return paronApi.post(`card/${id}/edit`, data)
    },

    deleteCardById(id) {
        return paronApi.delete(`card/${id}`)
    },

    getCardsByFilter(page, filter, sortOption, order) {
        var path = page ? `card/filtered?page=${page}&per_page=10&` : 'card/filtered?per_page=10'
        if (sortOption) {
            path += `sortOption=${sortOption}&order=${order}`
        }
        return paronApi.post(path, filter)
    }

}

<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-checkbox
                v-model="card.active"
                label="Active"
            />
            <v-checkbox
                v-model="card.vip"
                label="Vip"
            />
            <v-text-field
                v-model="card.number"
                label="Card Number"
                :clearable="clearable"
                :rules="rules"
            />
            <v-select
                v-model="card.type"
                :items="types"
                label="Card Type"
                :clearable="clearable"
                :rules="rules"
            />
            <v-text-field
                v-model="card.qr"
                label="Card QR"
                :clearable="clearable"
            />
            <v-autocomplete
                v-model="card.member"
                :rules="rules"
                :items="members"
                :item-text="getFieldText"
                menu-props="auto"
                label="Select Member"
                hide-details
                prepend-icon="mdi-domain"
                single-line
                :clearable="!readOnly"
                return-object
                deletable-chips
                chips
            />
            <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="card.expirationDate"
                        label="Choose your expiration date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        :readonly="readOnly"
                        :rules="rules"
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="card.expirationDate"
                    :model-config="modelConfig"
                    class="datepicker"
                    :readonly="readOnly"
                />
            </v-menu>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="my-2 white--text" large color="#004cff" :disabled="shouldDisableSubmit()" @click="onSubmit">
                Submit
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import moment from 'moment'
    import cardService from '@/services/card-service'
    import memberService from '@/services/member-service'
    import Alert from '@/components/Alert'

    export default {
        name: 'CardForm',
        components: { Alert },
        props: {
            index: {
                type: Number,
                required: true
            },
            memberCard: {
                type: Object,
                default: null
            },
            member: {
                type: Object,
                default: null
            },
            forceMode: {
                type: String,
                default: null
            }
        },
        data: () => {
            return {
                type: null,
                message: null,
                mode: null,
                readOnly: false,
                clearable: true,
                cardId: null,
                card: {
                    number: '',
                    active: false,
                    expirationDate: '',
                    member: '',
                    type: 'PARON',
                    qr: ''
                },
                types: [ 'PARON', 'My Market', 'AB' ],
                members: [],
                modelConfig: {
                    type: 'String',
                    mask: 'yyyy-MM-DD'
                },
                rules: [
                    v => !!v || 'Required Field'
                ]
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.forceMode) {
                this.mode = this.forceMode
            }
            this.getAllMembers()
            if (this.mode === 'create' && !this.forceMode) {
                return
            }
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
                this.expirationDateRules = []
            }
            this.cardId = this.$route.params.cardId
            if (this.memberCard) {
                this.card = this.memberCard
                this.card.member = this.member
                this.cardId = this.card.id
                this.card.expirationDate = this.formatDate(this.card.expirationDate, 'yyyy-MM-DD')
            } else if (this.cardId) {
                cardService.getCardById(this.cardId).then(resp => {
                    this.card = resp.data.card
                    this.card.expirationDate = this.formatDate(this.card.expirationDate, 'yyyy-MM-DD')
                })
            }
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return !this.card.expirationDate || !this.card.member || !this.card.number
            },
            getFieldText(member) {
                return `${member.firstName} ${member.lastName}`
            },
            onEdit() {
                this.$router.push(`/cards/${this.cardId}/edit`)
            },
            onSubmit() {
                this.prepareCardFields()
                if (this.mode === 'edit') {
                    cardService.editCardById(this.cardId, this.card).then(() => {
                        if (!this.forceMode) {
                            this.$router.push(`/cards`)
                        }
                        this.$emit('setAlert', 'Card edit was successfull', 'success')
                    }).catch((error) => {
                        this.$emit('setAlert', error, 'error')
                    })
                } else if (this.mode === 'create') {
                    cardService.createCard(this.card).then(() => {
                        if (!this.forceMode) {
                            this.$router.push(`/cards`)
                        }
                        this.$emit('setAlert', 'Card creation was successfull', 'success')
                    }).catch((error) => {
                        this.$emit('setAlert', error, 'error')
                    })
                }
            },
            getAllMembers() {
                if (this.mode !== 'show') {
                    memberService.getAllMembers().then((res) => {
                        this.members = res.data.data
                    })
                }
            },
            prepareCardFields() {
                if (this.card.id) {
                    delete this.card.id
                }
                if (this.card.member) {
                    this.card.member = this.card.member.id
                }
            },
            formatDate(date, format) {
                return moment(date).format(format)
            }
        }
    }
</script>

<style scoped>
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
